/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string,
} from 'prop-types';

const SearchIcon = ({
    fillColor,
}) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0833 3.4375C6.41294 3.4375 3.4375 6.41294 3.4375 10.0833C3.4375 13.7537 6.41294 16.7292 10.0833 16.7292C11.8806 16.7292 13.5113 16.0157 14.7075 14.8566C14.7281 14.8286 14.751 14.8017 14.7764 14.7764C14.8017 14.751 14.8286 14.7281 14.8566 14.7075C16.0157 13.5113 16.7292 11.8806 16.7292 10.0833C16.7292 6.41294 13.7537 3.4375 10.0833 3.4375ZM16.2203 15.248C17.3958 13.8526 18.1042 12.0507 18.1042 10.0833C18.1042 5.65355 14.5131 2.0625 10.0833 2.0625C5.65355 2.0625 2.0625 5.65355 2.0625 10.0833C2.0625 14.5131 5.65355 18.1042 10.0833 18.1042C12.0507 18.1042 13.8526 17.3958 15.248 16.2203L18.7639 19.7362C19.0324 20.0046 19.4677 20.0046 19.7362 19.7362C20.0046 19.4677 20.0046 19.0324 19.7362 18.7639L16.2203 15.248Z" fill={fillColor} />
    </svg>
);

SearchIcon.propTypes = {
    fillColor: string,
};

SearchIcon.defaultProps = {
    fillColor: '#555555',
};

export default SearchIcon;
